import React from "react"
import styled from "styled-components"

import Layout from "../../components/layout-canada"
import SEO from "../../components/seo"
import CocktailParty from "../../images/cocktail-party.jpg"
import LeafBorder from "../../images/hand-painted-leaf-border-cut.png"

const DetailText = styled.div`
  font-size: 38px;
  text-align: center;
  margin-top: 30px;
  line-height: 42px;
`

class CanadianCelebration extends React.Component {
  render() {
    return (
      <Layout hideWhereTo={true}>
        <SEO title="Canadian Celebration | Sara & Tim" />
        <DetailText>June 11th 2022</DetailText>
        <DetailText>Mission Ridge Road, Kelowna, B.C., Canada</DetailText>
      </Layout>
    )
  }
}

export default CanadianCelebration
